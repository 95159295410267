





































import Vue from 'vue'
import Component from 'vue-class-component'
import {Statues} from "@/shared/interfaces/Statues";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniCircleStatusButton from "@/components/buttons/SygniCircleStatusButton.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniLoader from '@/components/layout/SygniLoader.vue';
import {mapState} from "vuex";

@Component({
  components: {
    SygniCircleStatusButton,
    ExpandCollapse,
    SygniLoader,
    SygniRoundedButton, SygniTextArea, SygniCheckbox, SygniSelect, SygniInput, SygniContainerTitle},
  computed: {
    ...mapState('investors', {
      newInvestor: (state: any) => state.newInvestmentClient
    }),
  }
})
export default class AddProductSuccess extends Vue {
  showAnotherInvestor: boolean = false;
  isLoading: boolean = false;
  readonly statues = Statues;

  get productId() {
    return this.$route.query?.id;
  }

  get activeProduct() {
    return this.$store.getters['investors/newProduct']
  }

  get title() {
    if (this.activeProduct?.annexSourceReferenceIds?.length) {
      return (this.activeProduct?.annexType === 'annex') ? 'Annex was added' : 'Roll was added'
    }

    return 'Product was added'
  }

  get isSubscription() {
    return !!(this.activeProduct?.type === 'LLC_SHARES' || this.activeProduct?.type === 'JOINT_STOCK_SHARES')
  }

  showNewInvestorNotification(): void {
    this.$router.push(`/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise`);
  }

  goToProductPage() {
    if(this.productId) {
      this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise/product/summary/${(this.productId as string)}/for-legal-entity` });
    }
  }

  addAnotherSubscriptionInvestor() {
    if (this.isSubscription) {
      this.$router.push({ name: 'addNewProductStepOne', path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise/product/new/step-one`, params: { subscriptionId: this.activeProduct?.subscriptionId, productType: this.activeProduct?.type } })
    }
  }

  goToSubscriptionsList() {
    if (this.isSubscription) {
      this.$router.push(`/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise?page=subscriptions`)
    }
  }

  async mounted() {
    if (this.isSubscription) {
      if (this.activeProduct?.subscriptionId) {
        this.isLoading = true;
        try {
          const subscription = await this.$store.dispatch('subscriptions/getSubscription', this.activeProduct?.subscriptionId)
          this.showAnotherInvestor = !(subscription?.investmentReach >= subscription?.capitalValue)
        } catch (e) {
          e;
        }
        this.isLoading = false;
      }
    }
  }
}

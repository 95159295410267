import { render, staticRenderFns } from "./AddProductSuccess.vue?vue&type=template&id=1982a3ef&scoped=true&"
import script from "./AddProductSuccess.vue?vue&type=script&lang=ts&"
export * from "./AddProductSuccess.vue?vue&type=script&lang=ts&"
import style0 from "./AddProductSuccess.vue?vue&type=style&index=0&id=1982a3ef&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1982a3ef",
  null
  
)

export default component.exports